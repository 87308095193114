import { CountryCode as TCountryCode } from "./shopifyGraphQL/api";
export type CountryCode = Uppercase<keyof typeof TCountryCode>;
export const CountryCodes: Set<string> = new Set(Object.values(TCountryCode)) as any;

/**
 * Convert a presumed country code to an actual country code.
 * Typically this is used to convert a string to a member of the CountryCode
 * enum.
 */
export function toCountryCode(value: any): TCountryCode {
  if (!isCountryCode(value)) {
    throw new Error(`Unknown country code: ${value}`);
  }
  return value;
}

/**
 * Check if a value is a member of the CountryCode enum.
 */
function isCountryCode(value: any): value is TCountryCode {
  return Object.values(TCountryCode).includes(value);
}
