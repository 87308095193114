import * as yup from "yup";
import { CountryCode, CountryCodes } from "@smartrr/shared/countryCode";
import { IUninstantiatedCustomerRelationshipIdentifiers } from "./CustomerRelationship";
import { ICreateEntity, IShallowEntity, IShopifyMirroredEntityFields } from "./shared/SharedEntityFields";
import { entityValidations } from "./shared/YupValidation";
import { ISimpleAddress } from "../interfaces/Address";
import { MailingAddressInput } from "../shopifyGraphQL/api";
import { RequireNilFields } from "../utils/RequireNilFields";

export enum MailingAddressType {
  FOR_CUSTOMER = "FOR_CUSTOMER", // this type is for both a billing and mailing address: https://shopify.dev/docs/admin-api/graphql/reference/common-objects/mailingaddress
  GIFTING = "GIFTING",
}

// this makes sure we match GraphQL input for addresses
type CleanedShopifyMailingAddressInput = Omit<RequireNilFields<MailingAddressInput>, "id">;
type EnsuringShopifyMailingAddressInputFields = Partial<CleanedShopifyMailingAddressInput>;

interface IMailingAddressExtendsWithEnums
  extends ISimpleAddress,
    IUninstantiatedCustomerRelationshipIdentifiers,
    EnsuringShopifyMailingAddressInputFields,
    IShopifyMirroredEntityFields {}
export interface IMailingAddress extends Omit<IMailingAddressExtendsWithEnums, "countryCode"> {
  countryCode?: CountryCode;
  phoneCountryCode?: CountryCode;
  company?: string;
  shopifyId?: string;
  addressType: `${MailingAddressType}` | MailingAddressType;
}

export interface IMailingAddressWithShopifyId extends IMailingAddress {
  shopifyId: string;
}

// for saving as JSON, e.g. for orders (no ID associated)
export type IMailingAddressJson = Omit<
  IShallowEntity<ICreateEntity<IMailingAddress>>,
  "addressType" | "shopifyUpdateDate"
>;

export const validIMailingAddressJson = (v: any): boolean => {
  const supposedlyAddress: IMailingAddressJson = v;
  if (typeof supposedlyAddress !== "object") {
    return false;
  }

  if (supposedlyAddress.countryCode === undefined || !CountryCodes.has(supposedlyAddress.countryCode)) {
    return false;
  }

  return true;
};

export type IMailingAddressCreate = ICreateEntity<IMailingAddress>;
export type IMailingAddressUpsert = ICreateEntity<IMailingAddressWithShopifyId>;

export const { createValidation: mailngAddressCreateValidations, editValidation: mailingAddressEditValidations } =
  entityValidations<IMailingAddress>(
    {
      firstName: yup.string(),
      address1: yup.string().required(),
      address2: yup.string(),
      addressType: yup.string().required().oneOf(Object.values(MailingAddressType)),
      city: yup.string(),
      company: yup.string(),
      country: yup.string(),
      countryCode: yup.string(),
      email: yup.string(),
      lastName: yup.string(),
      latitude: yup.number(),
      longitude: yup.number(),
      phone: yup.string(),
      province: yup.string(),
      provinceCode: yup.string(),
      zip: yup.string(),
      phoneCountryCode: yup.string(),
    },
    true
  );
